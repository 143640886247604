
























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Post } from '~/themes/base/common/types'
import LazyPicture from '~components/utils/LazyPicture.vue'

interface ArticleTeaserPost {
  post?: Post
  search: {
    nodes?: Post[]
  }
}

@Component({
  components: {
    LazyPicture,
  },
})
export default class ArticleTeaserWidget extends Vue {
  @Prop({ type: Object, default: null })
  readonly initialData!: ArticleTeaserPost

  @Prop({ type: String, default: '' }) readonly badge!: string

  get post() {
    if (this.initialData.post) {
      return this.initialData.post
    } else if (
      this.initialData.search?.nodes &&
      this.initialData.search.nodes?.length > 0
    ) {
      return this.initialData.search.nodes[0]
    } else {
      return {}
    }
  }
}
